<template>
    <Loading :isLoading="isLoading" />
    <div class="flex ml-5 mt-5 cursor-pointer" @click="redirect_history()" >
        <img src="@/assets/back-arrow.svg">Atrás
    </div>
    <div class="bg-white m-0 px-5 md:px-3 md:m-5 ">
        <div class="grid grid-cols-1 md:grid-cols-2 px-0 md:px-0 lg:px-20 mt-10">

            <div class="w-full md:w-4/5 md:mx-auto mt-5">
                <p class="text-3xl font-bold roboto-regular mb-4">{{ questionTitle }}</p>
                
                <IssuanceCollectionComponent ref="ComponentsCollectionRef" :view_class_view="view_class_view" :validations="validations" :payment_methods="payment_methods" :payment_frequencies="payment_frequencies"/>

                
                <div class="w-full flex justify-center">
                   <input type="button" id="button_next" :disabled="!isActive" :class="{'button-disabled cursor-default': !isActive, 'bg-mustardDark cursor-pointer': isActive}" class="w-full text-white px-5 py-2 mx-auto my-4 h-14 md:h-14  rounded text-center font-medium text-xl" @click="next()" value="Continuar">        
                </div>
            </div>
            <div class="w-full bg-white hidden md:block md:py-14">
                <img src="@/assets/Cuenta/vidaSimple/collection.svg" :alt="questionTitle" class="mx-auto" >
            </div>
        </div> 
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { helpers, requiredIf } from '@vuelidate/validators';

import Loading from '@/components/Loading/VueLoading.vue';
import { availableFormStorage, getLocalStorage, createLocalStorage } from '@/helpers/storage.js';
import Quote from '@/classes/Quotation.js';


export default {
    name: "Collection",
    setup(){
        const router = useRouter();
        const route = useRoute();
        const isLoading = ref(true);
        const questionTitle = ref("Cobranza");
        const ComponentsCollectionRef = ref(null);
        const category = ref("");
        const quoteClass = ref(null);


        let view_class_view = {
            title: "hidden",
            class_inputs:{
                valid:"border-blue-600",
                no_valid:"border-red-600",
                default:"w-full py-2 px-2 rounded-md border-2 border-sm focus:border-blue-600 mb-2 mt-2 shadow-md focus:outline-none "
            },
            div_container_error:{
                container:"my-2",
                text_error:"text-red-600",
            },
            div_container:"grid grid-cols-1 sm:grid-cols-1 sm:gap-2",
        };

        const validations = {
            payment_method: {
                required: helpers.withMessage('El campo es requerido', requiredIf(true))
            },
            payment_frequency: {
                required: helpers.withMessage('El campo es requerido', requiredIf(true))
            }
        };

        const payment_methods = [{value: 'tarjeta de crédito', text: 'Tarjeta de crédito'}, {value: 'tarjeta de débito', text: 'Tarjeta de débito'}, {value: 'transferencia bancaria', text: 'Transferencia bancaria'}, {value: 'descuento por nomina', text: 'Descuento por nomina'}];
        const payment_frequencies = [{value: 'quincenal', text: 'Quincenal'}, {value: 'mensual', text: 'Mensual'}];
        
        let isActive = ref(true);

        onMounted(async () => {
            quoteClass.value = new Quote();
            category.value = getCategory();

            const quote = quoteClass.value.get(category.value);
            if(quote.is_additional_insured == "Para Adicional")
            {
                configuration_for_aditional();
            }

            isLoading.value = false;
            isActive.value = true;
            loadFormStorage();
        });

        function configuration_for_aditional()
        {
            set_title();
        }
        
        function set_title()
        {
            questionTitle.value += " para adicional";
        }
        
        const loadFormStorage = () => {
            try{
                if(!availableFormStorage('issuance-collection')) return;
                
                const data = getLocalStorage('issuance-collection');
            
                ComponentsCollectionRef.value.collectionModel.payment_frequency = data.payment_frequency;
                ComponentsCollectionRef.value.collectionModel.payment_method = data.payment_method;
            }catch(error){
                console.log("error:", error)
            }
        }

        const getCategory = () => {
            return route.params.product;
        }

        const saveData = (values, category) => {
            const nameStorage = `issuance-${category}`;
            let issuance = getLocalStorage(nameStorage);
            issuance.collection.payment_frequency = values.payment_frequency;
            issuance.collection.payment_method = values.payment_method;
            createLocalStorage(nameStorage, issuance)
        }

        const next = async ()   => {
            const ComponentsCollectionRefs = ComponentsCollectionRef.value.execute_validation();
            if(!ComponentsCollectionRefs) return false ;
            createLocalStorage('issuance-collection', ComponentsCollectionRefs);
            saveData(ComponentsCollectionRefs, category.value);
            router.push('beneficiarios')
        }

        const redirect_history = () => router.go(-1);


        return { 
            validations,
            view_class_view,
            isActive,
            next,
            questionTitle,
            ComponentsCollectionRef,
            payment_methods,
            payment_frequencies,
            redirect_history
        }
    },
    components:{
        Loading
    }

}
</script>
<style>
.question-container > div > div {
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
}

.question-container > div > div > button{
    border-radius:5px;
    width:49%;
}

.question-container>div>label, .question-container>div>input, .question-container>div>button {
     font-family: "roboto-regular";
}

.question-container > div > div > button.active {
    background-color: #810042;
    color: white;
    border-color: #810042;
}

.question-container > div > div > button {
    box-sizing: border-box;
    background: #D9D9D9;
    color: white;
    border-radius: 5px;
    font-size: 18px;
    margin-inline: 1px;
}

.question-container>div>label {
    font-size: 18px;
    line-height: 30px;
    color: #485462;
}

.card-shadow {
  box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -webkit-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
  -moz-box-shadow: 0px 4px 6px -3px rgb(178 176 176);
}

.content_textarea_0 {
    margin-top: 20px;
}

.content_date_3, .content_date_4 {
    margin-top: 9px;
}

.vpd-input-group input {
    flex-grow: 1;
    border: 2px solid #e5e7eb;
    border-right: 2px solid #e5e7eb;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    line-height: 30px;
    padding: 0 10px;
    height: 43px;
}

.content_textarea_6 {
    display:flex;
}

.content_checkbox_1 > div {
    width:100%!important;
}


@media only screen and (min-width: 600px) {
    .content_date_3, .content_date_4 {
        display: block;
    }
}

@media only screen and (max-width: 600px) {
    .content_date_3, .content_date_4 {
        display: flex;
    }
}

#input_rfc_contracting_data_1,
#input_curp_contracting_data_2{
    text-transform: uppercase;
}
</style>
<style scoped>
.button-disabled {
    background-color: #EDD191;
}



@media only screen and (min-width: 600px) {
    .detail-container {
        background-color: #FAFAFA;
    }
    .content_date_3, .content_date_4 {
        display: block;
    }
}
@media only screen and (max-width: 600px) {
    .bg-container {
        background-color: #FCFCFC;
    } 
    .content_date_3, .content_date_4 {
        display: flex;
    }
}
button {
    font-size: 19px!important;
}

</style>